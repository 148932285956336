import React from 'react';
import Reducer from "./Reducer";
import {
    createContext,
    useReducer,
} from "react";
import i18n from './i18n';


export const colors = {
    mainBlue: '#3b7fc4',
    mainBlueHover: '#4796e6',
    validationRed: '#c9282d',
    mainMenuColor: '#205081',
    mainWhite: '#ffffff',
    textBlue: '#007bff',
    textYellow: '#ffc107',
    textGreen: '#28a745',
    $textGray: '#777777'
}


export const theme = {
    direction: i18n.dir(),
    overrides: {
        MuiFormControl: {
            root: {
                width: '100%'
            }
        },
        MuiFormGroup: {
            root: {
                marginBottom: '20px'
            }
        },
        MuiDialogTitle: {
            root: {
                background: '#f5f5f5',
                borderBottom: '1px solid #c8c8c8',
                position: 'relative'
            }
        },
        MuiDialogContent: {
            root: {
                paddingTop: 20
            }
        },
        MuiDialogActions: {
            root: {
                background: '#f5f5f5',
                borderTop: '1px solid #c8c8c8'
            }
        },
        MuiPaper: {
            root: {
                border: '1px solid rgba(200, 200, 200, 1)',
                boxShadow: 'none'
            },
            elevation2: {
                boxShadow: 'none'
            }
        },
        MuiAppBar: {
            root: {
                border: 'none'
            },
        },
        MuiToolbar: {
            root: {
                '& [class*="MTableToolbar-title"]': {
                    '& h6': {
                        fontSize: 14
                    }
                },
                '& [class*="MTableToolbar-searchField"]': {
                    width: '300px !important',
                },
                '& [class*="MuiTextField-root"]': {
                    width: '300px !important',
                },
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(200, 200, 200, 1)',
            }
        },
        MuiTableFooter: {
            root: {
                '[class*="MuiTableCell-root"]': {
                    borderBottom: 'none'
                }
            }
        },
        MuiDrawer: {
            paper: {
                background: colors.mainMenuColor
            },
            Button: {
                Label: {
                    root:
                    {
                        color: colors.validationRed
                    }
                }
            }
        },
        MuiButtonBase: {
            root:
            {
                color: colors.mainMenuColor
            }
        },
        MuiTableCell: {
            head: {
                color: colors.mainMenuColor
            }
        },
        MuiIconButton: {
            label:
            {
                color: colors.mainBlue,
                fontFamily: [
                    'ApFont',
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    'Roboto',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(',')
            }
        },
        MuiPickersDay: {
            daySelected: {
                '& [class^="MuiIconButton-label"]': {
                    '& p': {
                        color: colors.mainWhite
                    }
                },
            }
        }
    },

    palette: {
        primary: {
            light: colors.mainBlue,
            main: colors.mainMenuColor,
        },
        textPrimary: {
            main: colors.mainBlue,
        },
        secondary: {
            main: colors.validationRed,
            light: colors.validationRed
        },
        error: {
            main: colors.validationRed
        }
    },
    typography: {
        fontFamily: [
            'ApFont',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
};

export const initialGlobalState = {
    user: {
        loggedIn: sessionStorage.getItem("A4L-AP-accessToken") ? true : false,
        data: sessionStorage.getItem("A4L-AP-user") ? JSON.parse(sessionStorage.getItem("A4L-AP-user")) : {}
    },
    modal: {
        title: '',
        open: false,
        showSaveButton: true,
        form: {
            id: '',
            name: '',
            submitCallback: '',
            openedFromMainMenu: false,
            data: {},
            targetGrid: null
        }
    },
    globalLoader: {
        open: false
    },
    globalWalkThrough: {
        open: false
    },
    mainMenu: {
        activeTab: ''
    },
    notificationBar: {
        open: false,
        text: '',
        severity: ''
    },
    litigationCasePage: {
        currentId: '',
        activeNavPanelIndex: 0
    },
    billEditForm: {
        currentId: '',
        activeNavPanelIndex: 0
    },
    afterActionCallbackFunction: '',
    domDirection: 'ltr',
    broadCastChannel: null,
    gridToReload: null,
    urlToGo: null,
    theme: {
        app_theme: "default",
        app_theme_version: "1",
        styles: {}
    },
    allowMatterEdit: true,
    fieldsPermissions: {
    },
    notesTranslations: [],
    isAiEnabled: false,
    translateAllNotesLanguage: null,
    showContractsTab: true,
    allowContractIdConfiguration: false,
    allowCaseIdConfiguration: false,
    dbVersion: "0.0.0",
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialGlobalState);

    return (
        <Context.Provider
            value={[state, dispatch]}
        >
            {children}
        </Context.Provider>
    );
};


export const Context = createContext(initialGlobalState);
export default Store;
